import { createContext, useContext } from 'solid-js';
import type PostHog from 'posthog-js';
import type { PostHog as PostHogNode } from 'posthog-node';
import type { ParentProps } from 'solid-js';

type PostHogWeb = typeof PostHog;

const Context = createContext<{ posthog: PostHogWeb | undefined }>();

type Props = ParentProps & {
	posthog?: PostHogWeb | PostHogNode;
};

export function AnalyticsProvider(props: Props) {
	return <Context.Provider value={{ posthog: props.posthog as PostHogWeb }}>{props.children}</Context.Provider>;
}

export function useAnalytics() {
	const ctx = useContext(Context);
	return ctx?.posthog;
}

export function useIsEnabled(key: string, defaultValue: boolean = false) {
	const ctx = useContext(Context);
	return !!(ctx?.posthog?.isFeatureEnabled(key) ?? defaultValue);
}

export function useTrackEvent(): Track {
	const ctx = useContext(Context);
	if (!ctx?.posthog) {
		return () => {};
	}

	return (event: AnalyticsEvent, properties?: Record<string, unknown>) => {
		ctx!.posthog!.capture(event, properties);
		if (event === 'logOutSuccess') {
			// Reset the user to the analytics platform
			// https://posthog.com/docs/libraries/js
			ctx!.posthog!.reset();
		}
	};
}

const noop = () => {};

export function useCapture(): PostHogWeb['capture'] {
	const ctx = useContext(Context);
	if (!ctx?.posthog) {
		return noop;
	}

	return ctx.posthog.capture.bind(ctx.posthog.capture);
}

export type AnalyticsRequestEvents =
	// Auth
	| 'login'
	| 'logOut'
	| 'signup'
	| 'emailMagicLink'
	| 'confirmEmailMagicLink'
	// Password reset
	| 'passwordResetCode'
	| 'passwordReset'
	// Tee times
	| 'getTeeTimes'
	| 'reserveTeeTime'
	| 'createTeeTimeAlert'
	| 'updateTeeTimeAlert'
	| 'deleteTeeTimeAlert'
	// Reservation Actions
	| 'attemptMobileCheckIn'
	| 'completeMobileCheckIn'
	// Inviting Users
	| 'inviteShare'
	| 'inviteUser'
	| 'removeUser'
	// Invite Actions
	| 'acceptInvitation'
	| 'rejectInvitation'
	// Reservation Actions
	| 'leaveReservation'
	| 'cancelReservation'
	// Check in
	| 'checkIn'
	// Credit cards
	| 'getCreditCardToken'
	| 'storeCreditCard'
	| 'addStoredCreditCard'
	// misc
	| 'getSupport';

export type AnalyticsEvent =
	| `${AnalyticsRequestEvents}Success`
	| `${AnalyticsRequestEvents}Request`
	| `${AnalyticsRequestEvents}Failure`
	// Dialogs
	| 'dialogOpened'
	| 'dialogClosed'
	// Sessions Events
	| 'sessionStart'
	| 'sessionEnd'
	// SignUp
	| 'signupEnteredEmail'
	| 'signupEnteredPassword'
	| 'signupEnteredName'
	| 'signupEnteredZipcode'
	// Screen Events
	| 'screenAppear'
	| 'screenDisappear'
	// Teetime Actions
	| 'changeTeeTimeDay'
	| 'didSelectTeeTime'
	| 'addToCalendar'
	// Push Actions
	| 'registerForPushAccess'
	| 'pushAccess'
	| 'receivedPushNotification'
	| 'tappedPushNotification'
	// Deeplinks
	| 'tappedDeeplink';

export type Track = (event: AnalyticsEvent, properties?: Record<string, unknown>) => void;
